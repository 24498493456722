import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Image, Carousel } from 'antd'
import { Parallax } from "react-parallax"
import { FormattedMessage } from 'react-intl'
import './index.less'

import logo from './images/palmatom_white.png'
import mediumLogo from './images/mediumLogo.png'
import medium from './images/medium.jpg'
import palmatom from './images/logo.png'
import mediumTop from './images/medium_top.png'
import mediumCenter from './images/medium_center.png'
import carousel1 from './images/01.jpg'
import carousel2 from './images/02.jpg'
import carousel3 from './images/03.jpg'
import carousel4 from './images/04.jpg'
import carousel5 from './images/05.jpg'
import carousel6 from './images/06.jpg'
import carousel7 from './images/07.jpg'
import carousel8 from './images/08.jpg'
import carousel9 from './images/09.jpg'
class Medium extends Component {
  state = {
  }

  paragraphRef = React.createRef()
  sectionPartRef = React.createRef()
  sectionPartTwoRef = React.createRef()
  sectionPartThreeRef = React.createRef()

  componentDidMount() {
    window.addEventListener('scroll', this.scrollListener)
    this.scrollListener()
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener)
  }

  scrollListener = () => {
    let scrollY = window.scrollY
    let innerHeight = window.innerHeight
    let offsetTop1 = this.sectionPartRef.current.offsetTop
    let offsetTop2 = this.sectionPartTwoRef.current.offsetTop
    let offsetTop3 = this.sectionPartThreeRef.current.offsetTop
    let offsetTop4 = this.paragraphRef.current.offsetTop
    let scrollYY1 = offsetTop1 - innerHeight
    let scrollYY2 = offsetTop2 - innerHeight
    let scrollYY3 = offsetTop3 - innerHeight
    let scrollYY4 = offsetTop4 - innerHeight
    let clientHeight1 = this.sectionPartRef.current.clientHeight
    let clientHeight2 = this.sectionPartTwoRef.current.clientHeight
    let clientHeight3 = this.sectionPartThreeRef.current.clientHeight
    let clientHeight4 = this.paragraphRef.current.clientHeight

    if (scrollY >= scrollYY1 && scrollY <= (scrollYY1 + clientHeight1)) {
      this.sectionPartRef.current.style.top = 50 * (1 - (scrollY - scrollYY1) / clientHeight1) + 'px'
    }
    if (scrollY >= scrollYY2 && scrollY <= (scrollYY2 + clientHeight2)) {
      this.sectionPartTwoRef.current.style.top = 50 * (1 - (scrollY - scrollYY2) / clientHeight2) + 'px'
    }
    if (scrollY >= scrollYY3 && scrollY <= (scrollYY3 + clientHeight3)) {
      this.sectionPartThreeRef.current.style.top = 50 * (1 - (scrollY - scrollYY3) / clientHeight3) + 'px'
    }
    if (scrollY >= scrollYY4 && scrollY <= (scrollYY4 + clientHeight4)) {
      this.paragraphRef.current.style.top = 50 * (1 - (scrollY - scrollYY4) / clientHeight4) + 'px'
    }
  }

  render() {
    return (
      <div className="medium">
        <Parallax bgImage={medium} bgImageStyle={{ width: '100%', height: window.innerWidth * (0.5625) }} strength={400}>
          <div style={{ height: window.innerWidth * (0.5625) }}>
            <div className="palmatomLogo" onClick={() => this.props.history.push('/admin/home')}>
              <Image width="100%" preview={false} src={logo} />
            </div>
            <div className="mediumLogo">
              <Image width="100%" preview={false} src={mediumLogo} />
            </div>
          </div>
        </Parallax>

        <div className="paragraph" ref={this.paragraphRef}>
          <div className="logo">
            <div className="logoImg">
              <Image width="100%" preview={false} src={palmatom}></Image>
            </div>
          </div>
          <div className="title"><FormattedMessage id="medium.title" /></div>
          <div className="line">——</div>
        </div>

        <div className="section">
          <div ref={this.sectionPartRef} className="section_part">
            <div className="text">
              <div className="subtitle"><FormattedMessage id="medium.strategy" /></div>
              <div className="subline">——</div>
              <div className="p"><FormattedMessage id="medium.strategyone" /></div>
              <div className="p"><FormattedMessage id="medium.strategytwo" /></div>
              <div className="p"><FormattedMessage id="medium.strategythree" /></div>
            </div>
            <div className="img"><Image width='100%' preview={false} src={mediumTop} /></div>
          </div>
          <div ref={this.sectionPartTwoRef} className="section_part">
            <div className="text">
              <div className="subtitle"><FormattedMessage id="medium.publicist" /></div>
              <div className="subline">——</div>
              <div className="p"><FormattedMessage id="medium.publicistone" /></div>
              <div className="p"><FormattedMessage id="medium.publicisttwo" /></div>
              <div className="p"><FormattedMessage id="medium.publicistthree" /></div>
            </div>
            <div className="img"><Image width='100%' preview={false} src={mediumCenter} /></div>
          </div>
          <div ref={this.sectionPartThreeRef} className="section_part">
            <div className="text">
              <div className="subtitle"><FormattedMessage id="medium.vision" /></div>
              <div className="subline">——</div>
              <div className="p"><FormattedMessage id="medium.visionone" /></div>
              <div className="p"><FormattedMessage id="medium.visiontwo" /></div>
              <div className="p"><FormattedMessage id="medium.visionthree" /></div>
            </div>
            <div className="img">
              <Carousel className="carousel" autoplay>
                <Image width="100%" preview={false} src={carousel1}></Image>
                <Image width="100%" preview={false} src={carousel2}></Image>
                <Image width="100%" preview={false} src={carousel3}></Image>
                <Image width="100%" preview={false} src={carousel4}></Image>
                <Image width="100%" preview={false} src={carousel5}></Image>
                <Image width="100%" preview={false} src={carousel6}></Image>
                <Image width="100%" preview={false} src={carousel7}></Image>
                <Image width="100%" preview={false} src={carousel8}></Image>
                <Image width="100%" preview={false} src={carousel9}></Image>
              </Carousel>
            </div>
          </div>
        </div>

        <div className="service" style={{ height: window.innerWidth * (0.54) }}><FormattedMessage id="medium.custom" /></div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userInfo: state.userInfo,
  }),
  {}
)(withRouter(Medium))
