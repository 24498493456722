import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Image } from 'antd'
import { Parallax } from "react-parallax"
import { FormattedMessage } from 'react-intl'
import './index.less'
import logo from './images/palmatom_white.png'
import joinLogo from './images/joinLogo.png'
import join from './images/join.jpg'
import line from './images/lines.png'

class Join extends Component {
  state = {
  }

  paragraphRef = React.createRef()

  componentDidMount() {
    // window.addEventListener('resize', this.resizeListener)
    // this.resizeListener()
    window.addEventListener('scroll', this.scrollListener)
    this.scrollListener()
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.resizeListener)
    window.removeEventListener('scroll', this.scrollListener)
  }

  // resizeListener = () => {
  //   let designSize = 1920
  //   let html = document.documentElement
  //   let clientW = html.clientWidth
  //   let htmlRem = clientW * 100 / designSize
  //   if (clientW > 1920) {
  //     html.style.fontSize = '100px'
  //   } else if (clientW < 1000) {
  //     html.style.fontSize = htmlRem + 'px'
  //     this.paragraphRef.current.style.top = 0
  //   } else {
  //     html.style.fontSize = htmlRem + 'px'
  //   }
  // }

  scrollListener = () => {
    let scrollY = window.scrollY
    let innerHeight = window.innerHeight
    let offsetTop = this.paragraphRef.current.offsetTop
    let scrollYY = offsetTop - innerHeight
    let clientHeight = this.paragraphRef.current.clientHeight

    if (scrollY >= scrollYY && scrollY <= (scrollYY + clientHeight)) {
      this.paragraphRef.current.style.top = 50 * (1 - (scrollY - scrollYY) / clientHeight) + 'px'
    }
  }

  render() {
    return (
      <div className="join">
        <Parallax bgImage={join} bgImageStyle={{ width: '100%', height: window.innerWidth * (0.5625) }} strength={400}>
          <div style={{ height: window.innerWidth * (0.5625) }}>
            <div className="palmatomLogo" onClick={() => this.props.history.push('/admin/home')}>
              <Image width="100%" preview={false} src={logo} />
            </div>
            <div className="joinLogo">
              <Image width="100%" preview={false} src={joinLogo} />
            </div>
          </div>
        </Parallax>

        <div className="paragraph" ref={this.paragraphRef}>
          <div className="text"><FormattedMessage id="join.textone" /></div>
          <div className="text"><FormattedMessage id="join.texttwo" /></div>
          <div className="line">
            <Image width="100%" preview={false} src={line}></Image>
          </div>
          <div className="text"><FormattedMessage id="join.textthree" /></div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userInfo: state.userInfo
  }),
  {}
)(withRouter(Join))
