const en_US = {
  'about': 'About PALMATOM',
  'about.locale': 'Chinese',
  'admin.aboutus': 'ABOUT US',
  'admin.section': 'BUSINESS SEGMENT',
  'admin.medium': 'CULTURAL MEDIA',
  'admin.strategy': 'STRATEGY',
  'admin.publicist': 'PUBLIC RELATIONS',
  'admin.vision': 'VISION',
  'admin.agriculture': 'AGRICULTURE',
  'admin.technology': 'SCIENCE AND TECHNOLOGY',
  'admin.design': 'PALMATOM DESIGN',
  'admin.join': 'JOIN US',
  'admin.contact': 'CONTACT US',
  'admin.locale': 'Chinese',
  'admin.footermedium': 'Cultural Media',
  'admin.footeragriculture': 'Agriculture',
  'admin.footertechnology': 'Science & technology',
  'admin.footerdesign': 'PALMATOM DESIGN',
  'admin.footerjoin': 'Join us',
  'admin.footercontact': 'Contact us',
  'admin.footer': '© All rights reserved by PALMATOM 2022',
  'home.one': 'Palm trees grow very slowly when it is young, and it takes three to five years for their stems to grow out of the soil,',
  'home.two': 'After another eight years, it can grow more than ten meters',
  'home.three': 'When the tree is high, the root is growing deeper, it can withstand hurricanes without breaking.',
  'home.four': 'The corporation named “Palmatom” which is developing with modesty and amiable, operating with plain and sincerity,',
  'home.five': 'then accumulating steadily, eventually explosive growth.',
  'home.medium': 'Cultural Media',
  'home.mediumSub1': 'Strategic public relations',
  'home.mediumSub2': 'Brand communication',
  'home.mediumSub3': 'Value empowerment',
  'home.agriculture': 'Agriculture',
  'home.agricultureSub1': 'Blueberry base',
  'home.agricultureSub2': 'Agricultural experimental base',
  'home.agricultureSub3': ' ',
  'home.technology': 'Science & Technology',
  'home.technologySub1': 'Vertical lifting',
  'home.technologySub2': 'New materials',
  'home.technologySub3': 'New energy agricultural machinery',
  'home.designone': 'The team has been engaged in aesthetic design for 13 years',
  'home.designtwo': 'Focus on the research and exploration of visual image design and various forms of visual space expansion',
  'medium.title': 'Cultural Media',
  'medium.strategy': 'STRATEGY',
  'medium.strategyone': 'Brand strategic positioning',
  'medium.strategytwo': 'Integrated marketing communication',
  'medium.strategythree': 'Public relations construction',
  'medium.publicist': 'PUBLIC RELATIONS',
  'medium.publicistone': 'PALMATOM MCN',
  'medium.publicisttwo': 'Among the first batch of MCN institutions in Zhihu',
  'medium.publicistthree': 'Comprehensive influence in 2021: Top 1',
  'medium.vision': 'VISION',
  'medium.visionone': 'Brand visual image and expansion design',
  'medium.visiontwo': 'Film and television advertising production',
  'medium.visionthree': 'Spatial visual design',
  'medium.custom': 'Serving Customer',
  'agriculture.title': 'Agriculture',
  'agriculture.pone': 'Implement "Rural Revitalization and Development" cooperation with the government in many places,',
  'agriculture.ptwo': 'to promote the development of agricultural modernization.',
  'agriculture.textone': 'Blueberry base',
  'agriculture.texttwo': 'Agricultural experimental base',
  'technology.title': 'Science & Technology',
  'technology.pone': 'Focus on the research and development of cutting-edge vertical lifting technology,',
  'technology.ptwo': 'new materials and new energy agricultural machinery.',
  'technology.textone': 'Vertical lifting',
  'technology.texttwo': 'New material',
  'technology.textthree': 'New energy agricultural machinery',
  'design.title': 'DESIGN',
  'design.p': 'Artistic creation and aesthetic design',
  'design.textone': 'Commercial aesthetics',
  'design.texttwo': 'Space sculpture',
  'design.textthree': 'Experimental art',
  'join.textone': 'You, who are full of enthusiasm for life',
  'join.texttwo': 'You, who have unlimited vision for good things',
  'join.textthree': 'Welcome to join us!',
  'contact.us': 'CONTACT US',
  'contact.address': 'Floor 2, Block A, Zheshui Building, No. 66, Yueming Road, Binjiang District, Hangzhou, Zhejiang',
  'contact.phone': '0571-86600510',
  'contact.title': 'Message',
  'contact.text': 'Thank you for watching and wish you a wonderful day.',
}
export default en_US
