import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Image } from 'antd'
import { Parallax } from "react-parallax"
import { FormattedMessage } from 'react-intl'
import './index.less'
import logo from './images/palmatom_white.png'
import technologyLogo from './images/technologyLogo.png'
import palmatom from './images/logo.png'
import technology from './images/technology.jpg'
import technologyLeft from './images/technology_left.png'
import technologyCenter from './images/technology_center.png'
import technologyRight from './images/technology_right.png'

class Technology extends Component {
  state = {
  }

  paragraphRef = React.createRef()
  spanRef = React.createRef()
  spanTwoRef = React.createRef()
  sectionPartRef = React.createRef()
  sectionPartTwoRef = React.createRef()
  sectionPartThreeRef = React.createRef()
  imgRef = React.createRef()
  imgTwoRef = React.createRef()
  imgThreeRef = React.createRef()

  componentDidMount() {
    // window.addEventListener('resize', this.resizeListener)
    // this.resizeListener()
    window.addEventListener('scroll', this.scrollListener)
    this.scrollListener()

    document.body.scrollTop = document.documentElement.scrollTop = 0
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.resizeListener)
    window.removeEventListener('scroll', this.scrollListener)
  }

  // resizeListener = () => {
  //   let designSize = 1920
  //   let html = document.documentElement
  //   let clientW = html.clientWidth
  //   let htmlRem = clientW * 100 / designSize
  //   if (clientW > 1920) {
  //     html.style.fontSize = '100px'
  //   } else if (clientW < 900) {
  //     html.style.fontSize = htmlRem + 'px'
  //     this.paragraphRef.current.style.top = 0
  //   } else {
  //     html.style.fontSize = htmlRem + 'px'
  //   }
  // }

  scrollListener = () => {
    let scrollY = window.scrollY
    let innerHeight = window.innerHeight
    let offsetTop1 = this.sectionPartRef.current.offsetTop
    let offsetTop2 = this.sectionPartTwoRef.current.offsetTop
    let offsetTop3 = this.sectionPartThreeRef.current.offsetTop
    let offsetTop4 = this.paragraphRef.current.offsetTop
    let scrollYY1 = offsetTop1 - innerHeight
    let scrollYY2 = offsetTop2 - innerHeight
    let scrollYY3 = offsetTop3 - innerHeight
    let scrollYY4 = offsetTop4 - innerHeight
    let clientHeight1 = this.sectionPartRef.current.clientHeight
    let clientHeight2 = this.sectionPartTwoRef.current.clientHeight
    let clientHeight3 = this.sectionPartThreeRef.current.clientHeight
    let clientHeight4 = this.paragraphRef.current.clientHeight

    if (scrollY >= scrollYY1 && scrollY <= (scrollYY1 + clientHeight1)) {
      this.sectionPartRef.current.style.top = 50 * (1 - (scrollY - scrollYY1) / clientHeight1) + 'px'
    }
    if (scrollY >= scrollYY2 && scrollY <= (scrollYY2 + clientHeight2)) {
      this.sectionPartTwoRef.current.style.top = 50 * (1 - (scrollY - scrollYY2) / clientHeight2) + 'px'
    }
    if (scrollY >= scrollYY3 && scrollY <= (scrollYY3 + clientHeight3)) {
      this.sectionPartThreeRef.current.style.top = 50 * (1 - (scrollY - scrollYY3) / clientHeight3) + 'px'
    }
    if (scrollY >= scrollYY4 && scrollY <= (scrollYY4 + clientHeight4)) {
      this.paragraphRef.current.style.top = 50 * (1 - (scrollY - scrollYY4) / clientHeight4) + 'px'
    }
    if (scrollY >= offsetTop1 && scrollY <= (offsetTop1 + clientHeight1)) {
      this.sectionPartRef.current.style.top = 50 * (scrollY - offsetTop1) / clientHeight1 + 'px'
    }
    if (scrollY >= offsetTop2 && scrollY <= (offsetTop2 + clientHeight2)) {
      this.sectionPartTwoRef.current.style.top = 50 * (scrollY - offsetTop2) / clientHeight2 + 'px'
    }
    if (scrollY >= offsetTop3 && scrollY <= (offsetTop3 + clientHeight3)) {
      this.sectionPartThreeRef.current.style.top = 50 * (scrollY - offsetTop3) / clientHeight3 + 'px'
    }
  }

  onMouseOver = (type) => {
    if (type === 'imgRef') {
      this.imgRef.current.style.transform = 'scale(1.2)'
      this.imgRef.current.style.transition = '1s'
    } else if (type === 'imgTwoRef') {
      this.imgTwoRef.current.style.transform = 'scale(1.2)'
      this.imgTwoRef.current.style.transition = '1s'
    } else {
      this.imgThreeRef.current.style.transform = 'scale(1.2)'
      this.imgThreeRef.current.style.transition = '1s'
    }
  }

  onMouseOut = (type) => {
    if (type === 'imgRef') {
      this.imgRef.current.style.transform = 'scale(1)'
      this.imgRef.current.style.transition = '1s'
    } else if (type === 'imgTwoRef') {
      this.imgTwoRef.current.style.transform = 'scale(1)'
      this.imgTwoRef.current.style.transition = '1s'
    } else {
      this.imgThreeRef.current.style.transform = 'scale(1)'
      this.imgThreeRef.current.style.transition = '1s'
    }
  }

  render() {
    return (
      <div className="technology">
        <Parallax bgImage={technology} bgImageStyle={{ width: '100%', height: window.innerWidth * (0.5625) }} strength={400}>
          <div style={{ height: window.innerWidth * (0.5625) }}>
            <div className="palmatomLogo" onClick={() => this.props.history.push('/admin/home')}>
              <Image width="100%" preview={false} src={logo} />
            </div>
            <div className="technologyLogo">
              <Image width="100%" preview={false} src={technologyLogo} />
            </div>
          </div>
        </Parallax>

        <div className="paragraph" ref={this.paragraphRef}>
          <div className="logo">
            <div className="logoImg">
              <Image width="100%" preview={false} src={palmatom}></Image>
            </div>
          </div>
          <div className="title"><FormattedMessage id="technology.title" /></div>
          <div className="line">——</div>
          <div className="p">
            <span style={{ display: this.props.localeInfo === 'en_US' ? 'block' : 'inline' }}><FormattedMessage id="technology.pone" /></span>
            <span style={{ display: this.props.localeInfo === 'en_US' ? 'block' : 'inline' }}><FormattedMessage id="technology.ptwo" /></span>
          </div>
        </div>

        <div className="section">
          <div ref={this.sectionPartRef} className="section_part">
            <div className="imgBox" onMouseOver={() => this.onMouseOver('imgRef')} onMouseOut={() => this.onMouseOut('imgRef')}>
              <img ref={this.imgRef} src={technologyLeft} alt="" />
            </div>
            <div className="text"><FormattedMessage id="technology.textone" /></div>
          </div>
          <div ref={this.sectionPartTwoRef} className="section_part">
            <div className="imgBox" onMouseOver={() => this.onMouseOver('imgTwoRef')} onMouseOut={() => this.onMouseOut('imgTwoRef')}>
              <img ref={this.imgTwoRef} src={technologyCenter} alt="" />
            </div>
            <div className="text"><FormattedMessage id="technology.texttwo" /></div>
          </div>
          <div ref={this.sectionPartThreeRef} className="section_part">
            <div className="imgBox" onMouseOver={() => this.onMouseOver('imgThreeRef')} onMouseOut={() => this.onMouseOut('imgThreeRef')}>
              <img ref={this.imgThreeRef} src={technologyRight} alt="" />
            </div>
            <div className="text"><FormattedMessage id="technology.textthree" /></div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    localeInfo: state.localeInfo
  }),
  {}
)(withRouter(Technology))
