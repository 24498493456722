import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PubSub from 'pubsub-js'
import { Button, Image } from 'antd'
import './index.less'
import { FormattedMessage } from 'react-intl'
import shadow from './images/12_AOV_PANDA.webm'
import logo from './images/logo.png'
import arrow from './images/arrow.png'
import { saveLocale } from '../../redux/actions/locale'
class About extends Component {
  state = {
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeListener)
    this.resizeListener()
    let shadowVideo = document.getElementById('shadowVideo')
    shadowVideo.addEventListener("ended", function () {
      shadowVideo.play()
    })
  }

  resizeListener = () => {
    if (document.documentElement.clientWidth > 1920) {
      document.getElementById('shadowVideo').style.width = 992 * (document.documentElement.clientWidth / 1920) + 'px'
    }
  }

  changeLocale = (locale) => {
    PubSub.publish('updateState', { locale })
    this.props.saveLocale(locale)
  }

  render() {
    return (
      <div className="about">
        <video id="shadowVideo" src={shadow} autoPlay muted></video>

        <div className="logo">
          <Image width='100%' preview={false} src={logo} />
        </div>

        <div className="about_box">
          <button className="aboutBtn" onClick={() => this.props.history.push('/admin/home')}>
            <FormattedMessage id="about" /><img className="arrowImg" alt="" src={arrow} />
          </button>
        </div>

        <div className="toggleBtn">
          <Button
            key="cn"
            type="link"
            className="left"
            onClick={() => this.changeLocale('zh_CN')}
          >
            <FormattedMessage id="about.locale" />
          </Button>|
          <Button
            key="en"
            type="link"
            className="right"
            onClick={() => this.changeLocale('en_US')}
          >
            English
        </Button>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userInfo: state.userInfo
  }),
  { saveLocale }
)(withRouter(About))
