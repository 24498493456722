import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import PubSub from 'pubsub-js'
import zh_CN from './locales/zh/index'
import en_US from './locales/en/index'
import zh from 'react-intl/locale-data/zh';
import en from 'react-intl/locale-data/en';

import About from './containers/about'
import Admin from './containers/admin'
import { IntlProvider, addLocaleData } from 'react-intl'

addLocaleData([...zh, ...en])
export default class App extends Component {
  state = {
    locale: 'zh_CN'
  }

  componentDidMount() {
    this.token = PubSub.subscribe('updateState', (_, obj) => {
      this.setState({ locale: obj.locale })
    })
  }

  getMessage = (locale) => {
    let returnMsg
    switch (locale) {
      case "zh_CN":
        returnMsg = zh_CN
        break;
      case "en_US":
        returnMsg = en_US
        break;
      default:
        return returnMsg
    }
    return returnMsg
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.token)
  }

  render() {
    const { locale } = this.state
    return (
      <IntlProvider locale={locale.split('_')[0]} messages={this.getMessage(locale)}>
        <Switch>
          <Route exact path="/" component={About} />
          <Route path="/admin" component={Admin} />
        </Switch>
      </IntlProvider>
    )
  }
}
