import { SAVE_MENUICON } from '../constant'

const initState = ''
export default function menuIconReducer(preState = initState, action) {
  const { type, data } = action
  let newState
  switch (type) {
    case SAVE_MENUICON:
      newState = data
      return newState

    default:
      return preState
  }
}