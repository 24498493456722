import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Image, Carousel } from 'antd'
import { FormattedMessage } from 'react-intl'
import './index.less'
import palmatom from './images/palmatom.png'
import contact from './images/contact.jpeg'
import lines from './images/lines.png'
import company_1 from './images/company_1.jpg'
import company_2 from './images/company_2.jpg'
import company_3 from './images/company_3.jpg'
import company_4 from './images/company_4.jpg'
import company_5 from './images/company_5.jpg'
import company_6 from './images/company_6.jpg'

class Contact extends Component {
  state = {
  }

  componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }

  render() {
    return (
      <div className="contact">
        <div className="palmatomLogo" onClick={() => this.props.history.push('/admin/home')}>
          <Image width="100%" preview={false} src={palmatom}></Image>
        </div>

        <div className="logo">
          <Image width="100%" preview={false} src={contact}></Image>
          <div className="contact_box">
            <div><FormattedMessage id="contact.us" /></div>
            <div className="line">
              <Image width="100%" preview={false} src={lines}></Image>
            </div>
            <div className="address">
              <div><FormattedMessage id="contact.address" /></div>
              <div><FormattedMessage id="contact.phone" /></div>
            </div>
          </div>
          <div className="img">
            <Carousel className="carousel" autoplay>
              <Image width="100%" preview={false} src={company_1}></Image>
              <Image width="100%" preview={false} src={company_2}></Image>
              <Image width="100%" preview={false} src={company_3}></Image>
              <Image width="100%" preview={false} src={company_4}></Image>
              <Image width="100%" preview={false} src={company_5}></Image>
              <Image width="100%" preview={false} src={company_6}></Image>
            </Carousel>
          </div>
        </div>

        <div className="box">
          <div><FormattedMessage id="contact.title" /></div>
          <div className="line">
            <Image width="100%" preview={false} src={lines}></Image>
          </div>
          <div className="text"><FormattedMessage id="contact.text" /></div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userInfo: state.userInfo
  }),
  {}
)(withRouter(Contact))
