import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import PubSub from 'pubsub-js'
import { withRouter, Link } from 'react-router-dom'
import { Layout, Button, Image, Drawer, Menu, Divider } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import menuIcon_black from './images/menu.png'
import menuIcon from './images/menu_1.png'
import menuIcon_gray from './images/menuIcon_gray.png'
import palmatomLogo from './images/footerLogo.png'
import x from './images/x.png'
import './index.less'
import { saveLocale } from '../../redux/actions/locale'

import Home from '../home'
import Medium from '../medium'
import Strategy from '../medium/strategy'
import Publicist from '../medium/publicist'
import Vision from '../medium/vision'
import Agriculture from '../agriculture'
import Technology from '../technology'
import Design from '../design'
import Join from '../join'
import Contact from '../contact'

const { Content, Footer } = Layout
const { SubMenu } = Menu

class Admin extends Component {
  state = {
    visible: false,
    selectKey: '',
    openSection: false,
    openMedium: false,
    zhColor: '#333738',
    enColor: '#83898c',
    menuIconState: false
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollListener)
    this.scrollListener()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener)
  }

  scrollListener = () => {
    let scrollY = window.scrollY
    let mediumHeight = window.innerWidth * (0.5625)
    if (scrollY < mediumHeight) {
      this.setState({ menuIconState: false })
    } else if (scrollY >= mediumHeight) {
      this.setState({ menuIconState: true })
    }
  }

  openDrawer = () => {
    this.setState({ visible: true })
  }

  onSelect = ({ item, key }) => {
    this.setState({
      visible: false,
      selectKey: key
    })
  }

  changeLocale = (locale) => {
    PubSub.publish('updateState', { locale })
    this.props.saveLocale(locale)
    if (locale === 'zh_CN') {
      this.setState({
        zhColor: '#333738',
        enColor: '#83898c',
      })
    } else {
      this.setState({
        zhColor: '#83898c',
        enColor: '#333738',
      })
    }
  }

  customExpandIcon = (props) => {
    if (props.children.length > 0) {
      return props.isOpen ? <MinusOutlined /> : <PlusOutlined />
    } else {
      return <span style={{ marginRight: 8 }}></span>
    }
  }

  render() {
    const { visible, zhColor, enColor, menuIconState } = this.state
    const { pathname } = this.props.location
    return (
      <Layout className="admin" style={{ minHeight: '100%', backgroundColor: '#fff' }}>
        <Button type="link" className="menuIcon" onClick={this.openDrawer}>
          <Image width="100%" preview={false} src={this.props.location.pathname.indexOf('contact') !== -1 ? menuIcon_black : menuIconState ? menuIcon_gray : menuIcon} />
        </Button>

        <Drawer
          className="drawer"
          width="26%"
          placement="right"
          visible={visible}
          closeIcon={<Image width={20 * (window.innerWidth / 1920)} preview={false} src={x} />}
          headerStyle={{ backgroundColor: '#e6eaed', padding: '9% 12%' }}
          bodyStyle={{ backgroundColor: '#e6eaed', padding: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          onClose={() => this.setState({ visible: false })}
        >
          <Menu
            className="drawer-menu"
            defaultSelectedKeys={[pathname.indexOf('home') !== -1 ? 'home' : pathname.split('/').reverse()[0]]}
            mode="inline"
            inlineIndent={0}
            expandIcon={(props) => this.customExpandIcon(props)}
            onSelect={this.onSelect}
          >
            <Menu.Item key="home" style={{ borderTop: '1px solid #cacacb50' }}>
              <Link to='/admin/home' children={<span><FormattedMessage id="admin.aboutus" /></span>} />
            </Menu.Item>
            <SubMenu key="section" style={{ borderTop: '1px solid #cacacb50' }} title={<span><FormattedMessage id="admin.section" /></span>}>
              <SubMenu key="medium" title={<span><FormattedMessage id="admin.medium" /></span>} onTitleClick={() => this.props.history.push('/admin/section/medium')}>
                <Menu.Item key="strategy">
                  <Link to='/admin/section/medium/strategy' children={<span><FormattedMessage id="admin.strategy" /></span>} />
                </Menu.Item>
                <Menu.Item key="publicist">
                  <Link to='/admin/section/medium/publicist' children={<span><FormattedMessage id="admin.publicist" /></span>} />
                </Menu.Item>
                <Menu.Item key="vision">
                  <Link to='/admin/section/medium/vision' children={<span><FormattedMessage id="admin.vision" /></span>} />
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="agriculture">
                <Link to='/admin/section/agriculture' children={<span><FormattedMessage id="admin.agriculture" /></span>} />
              </Menu.Item>
              <Menu.Item key="technology">
                <Link to='/admin/section/technology' children={<span><FormattedMessage id="admin.technology" /></span>} />
              </Menu.Item>
              <Menu.Item key="design">
                <Link to='/admin/section/design' children={<span><FormattedMessage id="admin.design" /></span>} />
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="join" style={{ borderTop: '1px solid #cacacb50' }}>
              <Link to='/admin/join' children={<span><FormattedMessage id="admin.join" /></span>} />
            </Menu.Item>
            <Menu.Item key="contact" style={{ borderTop: '1px solid #cacacb50', borderBottom: '1px solid #cacacb50' }}>
              <Link to='/admin/contact' children={<span><FormattedMessage id="admin.contact" /></span>} />
            </Menu.Item>
          </Menu>

          <div className="toggle" style={{ width: '100%', height: '13%', backgroundColor: '#d3d9dd', fontSize: 12, color: '#83898c', display: 'flex', alignItems: 'center', paddingLeft: '8%' }}>
            <div>
              <Button
                key="cn"
                type="link"
                style={{ fontSize: 12, color: zhColor }}
                onClick={() => this.changeLocale('zh_CN')}
              >
                <FormattedMessage id="admin.locale" />
              </Button>|
            <Button
                key="en"
                type="link"
                style={{ fontSize: 12, color: enColor }}
                onClick={() => this.changeLocale('en_US')}
              >
                English
            </Button>
            </div>
          </div>
        </Drawer>

        <Content>
          <Switch>
            <Route path='/admin/home' component={Home} />
            <Route exact path='/admin/section/medium' component={Medium} />
            <Route path='/admin/section/medium/strategy' component={Strategy} />
            <Route path='/admin/section/medium/publicist' component={Publicist} />
            <Route path='/admin/section/medium/vision' component={Vision} />
            <Route path='/admin/section/agriculture' component={Agriculture} />
            <Route path='/admin/section/technology' component={Technology} />
            <Route path='/admin/section/design' component={Design} />
            <Route path='/admin/join' component={Join} />
            <Route path='/admin/contact' component={Contact} />
          </Switch>
        </Content>

        <Footer className="footer" style={{ backgroundColor: this.props.location.pathname.indexOf('medium') !== -1 ? '#fff' : '#f6f7f9' }}>
          <Image width={230 * (window.innerWidth / 1920)} preview={false} src={palmatomLogo} />
          <Divider className="divider" />
          <div className="text">
            <div className="button_left">
              <Button className="button" type="link" style={{ paddingLeft: 0 }} onClick={() => this.props.history.push('/admin/section/medium')}><FormattedMessage id="admin.footermedium" /></Button>|
              <Button className="button" type="link" onClick={() => this.props.history.push('/admin/section/agriculture')}><FormattedMessage id="admin.footeragriculture" /></Button>|
              <Button className="button" type="link" onClick={() => this.props.history.push('/admin/section/technology')}><FormattedMessage id="admin.footertechnology" /></Button>|
              <Button className="button" type="link" onClick={() => this.props.history.push('/admin/section/design')}><FormattedMessage id="admin.footerdesign" /></Button>
              <Button className="button button-five" type="link" onClick={() => this.props.history.push('/admin/contact')}><FormattedMessage id="admin.footercontact" /></Button>|
              <Button className="button" type="link" onClick={() => this.props.history.push('/admin/join')}><FormattedMessage id="admin.footerjoin" /></Button>
            </div>
            <div className="version"><FormattedMessage id="admin.footer" /></div>
          </div>
        </Footer>
      </Layout>
    )
  }
}

export default connect(
  state => ({
    userInfo: state.userInfo,
  }),
  {
    saveLocale
  }
)(withRouter(Admin))
