import { SAVE_LOCALE } from '../constant'

const initState = ''
export default function localeReducer(preState = initState, action) {
  const { type, data } = action
  let newState
  switch (type) {
    case SAVE_LOCALE:
      newState = data
      return newState

    default:
      return preState
  }
}