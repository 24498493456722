const zh_CN = {
  'about': '关于棕喜',
  'about.locale': '中文',
  'admin.aboutus': '关于我们',
  'admin.section': '业务板块',
  'admin.medium': '文化传媒',
  'admin.strategy': '策略',
  'admin.publicist': '公关',
  'admin.vision': '视觉',
  'admin.agriculture': '农业',
  'admin.technology': '科技',
  'admin.design': '设计',
  'admin.join': '加入我们',
  'admin.contact': '联系我们',
  'admin.locale': '中文',
  'admin.footermedium': '文化传媒',
  'admin.footeragriculture': '农业',
  'admin.footertechnology': '科技',
  'admin.footerdesign': '设计',
  'admin.footerjoin': '加入我们',
  'admin.footercontact': '联系我们',
  'admin.footer': '© 棕喜 2022 版权所有',
  'home.one': '棕榈树幼年期生长十分缓慢，其茎长出土壤表面需三至五年，',
  'home.two': '其后再八年，方可高达十余米',
  'home.three': '待树高，根愈深，耐飓风，不折不断。',
  'home.four': '企业以“棕喜”为号，秉持谦静随和的态度踏实发展，',
  'home.five': '厚积薄发，朴诚经营。',
  'home.medium': '文化传媒',
  'home.mediumSub1': '战略公关 ｜ ',
  'home.mediumSub2': '品牌传播 ｜ ',
  'home.mediumSub3': '价值赋能',
  'home.agriculture': '农业',
  'home.agricultureSub1': '蓝莓基地 ｜ ',
  'home.agricultureSub2': '农业实验基地',
  'home.agricultureSub3': ' ',
  'home.technology': '科技',
  'home.technologySub1': '机器人 ｜ ',
  'home.technologySub2': '新能源农机 ｜ ',
  'home.technologySub3': '新材料',
  'home.designone': '团队从事美学设计13年',
  'home.designtwo': '专注于研究及探索视觉形象设计及各类视觉空间延展形式',
  'medium.title': '文化传媒',
  'medium.strategy': '策略',
  'medium.strategyone': '品牌战略定位',
  'medium.strategytwo': '整合营销传播',
  'medium.strategythree': '公关建设',
  'medium.publicist': '公关',
  'medium.publicistone': '棕喜MCN',
  'medium.publicisttwo': '知乎首批入驻MCN机构',
  'medium.publicistthree': '2021年综合影响力TOP1',
  'medium.vision': '视觉',
  'medium.visionone': '品牌视觉形象及延展设计',
  'medium.visiontwo': '影视广告制作',
  'medium.visionthree': '空间视觉设计',
  'medium.custom': '服务客户',
  'agriculture.title': '农业',
  'agriculture.pone': '在多地与政府开展“乡村振兴发展”合作，',
  'agriculture.ptwo': '推进农业现代化发展。',
  'agriculture.textone': '蓝莓基地',
  'agriculture.texttwo': '农业实验基地',
  'technology.title': '科技',
  'technology.pone': '专注前沿机器人技术研发，',
  'technology.ptwo': '新材料研究以及新材料的研发。',
  'technology.textone': '机器人',
  'technology.texttwo': '新能源农机',
  'technology.textthree': '新材料',
  'design.title': '设计',
  'design.p': '艺术创作、美学设计',
  'design.textone': '商业美学',
  'design.texttwo': '空间雕塑',
  'design.textthree': '实验艺术',
  'join.textone': '对生活充满热情的你',
  'join.texttwo': '对美好的事物有着无限憧憬的你',
  'join.textthree': '欢迎加入我们！',
  'contact.us': '联系我们',
  'contact.address': '浙江省杭州市滨江区月明路66号浙水大厦A座2层',
  'contact.phone': '0571-86600510',
  'contact.title': '寄语',
  'contact.text': '感谢观看，愿美好陪伴您每一天。',
}
export default zh_CN
