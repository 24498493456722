import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Parallax } from "react-parallax"
import { FormattedMessage } from 'react-intl'
import { Image } from 'antd'
import {
  Player,
  ControlBar,
} from 'video-react'
import 'video-react/dist/video-react.css'
import './index.less'

import palmatom from './images/palmatom_white.png'
import zongxi from './images/zongxi.png'
import bgImg from './images/bg.jpg'
import mediumImg from './images/medium.png'
import agricultureImg from './images/agriculture.png'
import technologyImg from './images/technology.png'
import designbg from './images/designbg.jpeg'
import designLogo from './images/designLogo.png'
import videoImg from './images/video.jpg'
class Home extends Component {
  state = {
  }

  paragraphRef = React.createRef()
  sectionPartRef = React.createRef()
  sectionPartTwoRef = React.createRef()
  sectionPartThreeRef = React.createRef()
  imgRef = React.createRef()
  imgTwoRef = React.createRef()
  imgThreeRef = React.createRef()

  componentDidMount() {
    window.addEventListener('scroll', this.scrollListener)
    this.scrollListener()

    document.body.scrollTop = document.documentElement.scrollTop = 0
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener)
  }

  scrollListener = () => {
    let scrollY = window.scrollY
    let innerHeight = window.innerHeight
    let offsetTop1 = this.sectionPartRef.current.offsetTop
    let offsetTop2 = this.sectionPartTwoRef.current.offsetTop
    let offsetTop3 = this.sectionPartThreeRef.current.offsetTop
    let offsetTop4 = this.paragraphRef.current.offsetTop
    let scrollYY1 = offsetTop1 - innerHeight
    let scrollYY2 = offsetTop2 - innerHeight
    let scrollYY3 = offsetTop3 - innerHeight
    let scrollYY4 = offsetTop4 - innerHeight
    let clientHeight1 = this.sectionPartRef.current.clientHeight
    let clientHeight2 = this.sectionPartTwoRef.current.clientHeight
    let clientHeight3 = this.sectionPartThreeRef.current.clientHeight
    let clientHeight4 = this.paragraphRef.current.clientHeight

    if (scrollY >= scrollYY1 && scrollY <= (scrollYY1 + clientHeight1)) {
      this.sectionPartRef.current.style.top = 50 * (1 - (scrollY - scrollYY1) / clientHeight1) + 'px'
    }
    if (scrollY >= scrollYY2 && scrollY <= (scrollYY2 + clientHeight2)) {
      this.sectionPartTwoRef.current.style.top = 50 * (1 - (scrollY - scrollYY2) / clientHeight2) + 'px'
    }
    if (scrollY >= scrollYY3 && scrollY <= (scrollYY3 + clientHeight3)) {
      this.sectionPartThreeRef.current.style.top = 50 * (1 - (scrollY - scrollYY3) / clientHeight3) + 'px'
    }
    if (scrollY >= scrollYY4 && scrollY <= (scrollYY4 + clientHeight4)) {
      this.paragraphRef.current.style.top = 50 * (1 - (scrollY - scrollYY4) / clientHeight4) + 'px'
    }
  }

  onMouseOver = (type) => {
    if (type === 'imgRef') {
      this.imgRef.current.style.transform = 'scale(1.2)'
      this.imgRef.current.style.transition = '1s'
    } else if (type === 'imgTwoRef') {
      this.imgTwoRef.current.style.transform = 'scale(1.2)'
      this.imgTwoRef.current.style.transition = '1s'
    } else {
      this.imgThreeRef.current.style.transform = 'scale(1.2)'
      this.imgThreeRef.current.style.transition = '1s'
    }
  }

  onMouseOut = (type) => {
    if (type === 'imgRef') {
      this.imgRef.current.style.transform = 'scale(1)'
      this.imgRef.current.style.transition = '1s'
    } else if (type === 'imgTwoRef') {
      this.imgTwoRef.current.style.transform = 'scale(1)'
      this.imgTwoRef.current.style.transition = '1s'
    } else {
      this.imgThreeRef.current.style.transform = 'scale(1)'
      this.imgThreeRef.current.style.transition = '1s'
    }
  }

  render() {
    return (
      <div className="home">
        <Parallax bgImage={bgImg} bgImageStyle={{ width: '100%', height: window.innerWidth * (0.5625) }} strength={400}>
          <div style={{ height: window.innerWidth * (0.5625) }}>
            <div className="palmatomLogo" onClick={() => this.props.history.push('/')}>
              <Image width="100%" preview={false} src={palmatom} />
            </div>
            <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', width: '9.4%' }}><Image width="100%" preview={false} src={zongxi} /></div>
          </div>
        </Parallax>

        <div className="paragraph" ref={this.paragraphRef}>
          <div className="palmatom">PALMATOM</div>
          <div className="line">——</div>
          <div className="p"><FormattedMessage id="home.one" /></div>
          <div className="p"><FormattedMessage id="home.two" /></div>
          <div className="p"><FormattedMessage id="home.three" /></div>
          <div className="p" style={{ marginTop: '3%' }}><FormattedMessage id="home.four" /></div>
          <div className="p"><FormattedMessage id="home.five" /></div>
        </div>

        <div className="section">
          <div ref={this.sectionPartRef} id="sectionPart" className="section_part">
            <div className="imgBox" onMouseOver={() => this.onMouseOver('imgRef')} onMouseOut={() => this.onMouseOut('imgRef')}>
              <img ref={this.imgRef} src={mediumImg} alt="" onClick={() => this.props.history.push('/admin/section/medium')} />
            </div>
            <div className="text">
              <div className="title"><FormattedMessage id="home.medium" /></div>
              <div>
                <span style={{ display: this.props.localeInfo === 'en_US' ? 'block' : 'inline', textAlign: 'center' }}><FormattedMessage id="home.mediumSub1" /></span>
                <span style={{ display: this.props.localeInfo === 'en_US' ? 'block' : 'inline', textAlign: 'center' }}><FormattedMessage id="home.mediumSub2" /></span>
                <span style={{ display: this.props.localeInfo === 'en_US' ? 'block' : 'inline', textAlign: 'center' }}><FormattedMessage id="home.mediumSub3" /></span>
              </div>
            </div>
          </div>
          <div ref={this.sectionPartTwoRef} className="section_part">
            <div className="imgBox" onMouseOver={() => this.onMouseOver('imgTwoRef')} onMouseOut={() => this.onMouseOut('imgTwoRef')}>
              <img ref={this.imgTwoRef} src={agricultureImg} alt="" onClick={() => this.props.history.push('/admin/section/agriculture')} />
            </div>
            <div className="text">
              <div className="title"><FormattedMessage id="home.agriculture" /></div>
              <div>
                <span style={{ display: this.props.localeInfo === 'en_US' ? 'block' : 'inline', textAlign: 'center' }}><FormattedMessage id="home.agricultureSub1" /></span>
                <span style={{ display: this.props.localeInfo === 'en_US' ? 'block' : 'inline', textAlign: 'center' }}><FormattedMessage id="home.agricultureSub2" /></span>
                <span style={{ display: this.props.localeInfo === 'en_US' ? 'block' : 'inline', textAlign: 'center' }}><FormattedMessage id="home.agricultureSub3" /></span>
              </div>
            </div>
          </div>
          <div ref={this.sectionPartThreeRef} className="section_part">
            <div className="imgBox" onMouseOver={() => this.onMouseOver('imgThreeRef')} onMouseOut={() => this.onMouseOut('imgThreeRef')}>
              <img ref={this.imgThreeRef} src={technologyImg} alt="" onClick={() => this.props.history.push('/admin/section/technology')} />
            </div>
            <div className="text">
              <div className="title"><FormattedMessage id="home.technology" /></div>
              <div>
                <span style={{ display: this.props.localeInfo === 'en_US' ? 'block' : 'inline', textAlign: 'center' }}><FormattedMessage id="home.technologySub1" /></span>
                <span style={{ display: this.props.localeInfo === 'en_US' ? 'block' : 'inline', textAlign: 'center' }}><FormattedMessage id="home.technologySub2" /></span>
                <span style={{ display: this.props.localeInfo === 'en_US' ? 'block' : 'inline', textAlign: 'center' }}><FormattedMessage id="home.technologySub3" /></span>
              </div>
            </div>
          </div>
        </div>

        <Parallax style={{ marginTop: '6%' }} bgImage={designbg} bgImageStyle={{ height: window.innerWidth * (0.67) }} strength={-350}>
          <div style={{ height: window.innerWidth * (0.2396) }} onClick={() => this.props.history.push('/admin/section/design')}>
            <div className="designBgImg">
              <div className="designContent">
                <Image width="60%" className="design_img" preview={false} src={designLogo} />
                <span style={{ lineHeight: this.props.localeInfo === 'en_US' ? 1.5 : 3, paddingTop: this.props.localeInfo === 'en_US' ? '1%' : 0 }} className="design_text"><FormattedMessage id="home.designone" /></span>
                <span style={{ lineHeight: this.props.localeInfo === 'en_US' ? 1.5 : 1 }} className="design_text"><FormattedMessage id="home.designtwo" /></span>
              </div>
            </div>
          </div>
        </Parallax>

        <Player
          playsInline={true}
          // autoPlay={true}
          // muted={true}
          poster={videoImg}
          src="" // https://media.w3.org/2010/05/sintel/trailer_hd.mp4
        >
          <ControlBar autoHide={false} disableDefaultControls={false}>
            {/* <PlayToggle />
            <VolumeMenuButton />
            <CurrentTimeDisplay order={4.1} />
            <TimeDivider order={4.2} />
            <DurationDisplay />
            <ProgressControl />
            <FullscreenToggle /> */}
          </ControlBar>
        </Player>
      </div>
    )
  }
}

export default connect(
  state => ({
    localeInfo: state.localeInfo
  }),
  {}
)(withRouter(Home))
